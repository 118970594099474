import React from 'react';
import { graphql, Link } from 'gatsby';
import tjenesterBoxer from '../images/boxes/tjeneste_boxer.svg';
import tjenesterBoxer2 from '../images/boxes/tjeneste_boxer_2.svg';
import ListItem from '../images/boxes/listitem.svg';
import Img from 'gatsby-image';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';
import styles from './Services.module.scss';

export const query = graphql`
  query {
    city: file(relativePath: { eq: "tjenester/city.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ServicesPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <Layout>
        <SEO title="Services. We provide you with expert risk assessments and guidance in matters related to hydrogen, ammonia or other gases." />
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-32">
          <div>
            <h1 className={styles.welcomeText}>What we can offer</h1>
            <p className={styles.descriptionText}>
              Our society depends entirely on energy sources and carriers, and safety is paramount
              when handling these. If the energy is released too quickly, an explosion, a fire, or a
              run-away reaction can occur, yielding a potential hazard that exposes individuals or
              assets to serious risk. For conventional energy carriers used for decades or even
              centuries, such as coal or gas, risks and hazards are generally understood and
              acknowledged by users, including ordinary citizens. For potential zero-emission energy
              carriers like hydrogen, but also, ammonia and Li-ion batteries, the hazards are less
              understood.
            </p>
            <p className={styles.descriptionText}>
              HYEX’ services help energy infrastructure project teams, along with energy consumers,
              understand such hazards. Infrastructure projects include production, distribution, or
              storage systems, while relevant consumers range from industrial plants and buildings,
              to ships, vehicles, and trains. We advise how to handle and use new and conventional
              energy carriers safely, exposing staff, customers and others to risks as low as
              practically possible, well within tolerable levels.
            </p>
            <div className={styles.descriptionText}>
              Some of our services:
              <div className={styles.projectListContainer}>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Early design
                  screening to identify hazards and propose design changes.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> HAZID workshops to
                  identify major hazards in a systematic way.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Risk assessments
                  related to permitting processes with national authorities and class societies, or
                  for project decision gates.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Dedicated
                  CFD-studies to assess a safety challenge and to find cost-efficient ways to
                  improve system design.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Specialist safety
                  studies for larger oil and gas installations are provided. Larger risk assessments
                  are not our primary scope, but such can be provided together with our business
                  partners.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Incident
                  investigation support to understand incidents/accidents or potential of
                  near-misses.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Identification of
                  potential hazards, to determine how incidents can be prevented in the future.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Research and
                  training courses on explosion risk and understanding of the relevant mechanisms.
                </div>
              </div>
            </div>
            <div className={styles.navContainer}>
              <p className={styles.descriptionText}> Select a topic below to read more:</p>
              <div className={styles.boxContainer}>
                <img src={tjenesterBoxer} alt="boxes" />
                <div className={styles.navBox}>
                  <ul className={styles.navList}>
                    <Link className={styles.navItem} to={'/services/hydrogen'}>
                      Hydrogen
                    </Link>
                    <Link className={styles.navItem} to={'/services/ammonia'}>
                      Ammonia
                    </Link>
                    <Link className={styles.navItem} to={'/services/gas-dispersion'}>
                      Gas dispersion
                    </Link>
                    <Link className={styles.navItem} to={'/services/explosion-studies'}>
                      Explosion studies
                    </Link>
                    <Link className={styles.navItem} to={'/services/incident-investigation-support'}>
                      Incident investigation support
                    </Link>
                    <Link className={styles.navItem} to={'/services/other-services'}>
                      Other services
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.city.childImageSharp.fluid} alt="City skyline" />
            </figure>
          </div>
        </div>
        <div className={styles.boxContainer2}>
          <img src={tjenesterBoxer2} />
        </div>
      </Layout>
    </>
  );
};

export default ServicesPage;
